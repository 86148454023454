<template>
  <v-layout row wrap>
    <v-flex xs12 class="no-print">
      <v-card>
        <v-toolbar dark class="secondary" fluid dense>
          <v-toolbar-title>Copy problems and replacements data collect</v-toolbar-title>
        </v-toolbar>
        <v-progress-linear style="position: absolute" v-show="loading" :indeterminate="true" class="ma-0"></v-progress-linear>
        <v-card-text>
          <v-form ref="form" autocomplete="off" v-model="validForm">
            <v-layout row wrap>
              <v-flex md12>
                <v-autocomplete
                  box
                  hid
                  :disabled="loading"
                  :items="clients"
                  v-model="clientId"
                  label="Client"
                  prepend-icon="fa-user"
                  item-text="clientName"
                  item-value="clientId"
                  @change="clientSelect"
                >
                  <template slot="item" slot-scope="{ item }">
                    <v-list-tile-avatar>
                      <img :style="!item.active ? 'opacity: 0.5' : ''" :src="`images/${item.gender ? item.gender.toLowerCase() : 'nogender'}.png`" />
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title :class="{ 'grey--text text--lighten-1': !item.active }">{{ item.clientName }}</v-list-tile-title>
                      <v-list-tile-sub-title :class="{ 'grey--text text--lighten-1': !item.active }">
                        {{ item.dob | moment("utc", "MM/DD/YYYY") }} | Code: {{ item.clientCode || "N/A" }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-layout row wrap v-if="clientId">
                <v-flex xs12>
                  <v-radio-group v-model="collection" label="I'm going to copy data in" class="mt-0 pb-3" hide-details :disabled="loading">
                    <v-radio label="Behaviors" value="behavior" color="primary"> </v-radio>
                    <v-radio label="Replacements" value="replacement" color="primary"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-layout row wrap v-if="collection == 'behavior'">
                  <v-flex xs12 md6>
                    <v-select
                      box
                      :disabled="loading"
                      :items="clientBehaviors"
                      v-model="originBehavior"
                      label="Copy from behavior (origin)"
                      item-text="problemBehavior.problemBehaviorDescription"
                      item-value="problemId"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-select
                      box
                      :disabled="loading"
                      :items="clientBehaviors"
                      v-model="targetBehavior"
                      label="to behavior (target)"
                      item-text="problemBehavior.problemBehaviorDescription"
                      item-value="problemId"
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-else>
                  <v-flex xs12 md6>
                    <v-select
                      box
                      :disabled="loading"
                      :items="clientReplacements"
                      v-model="originReplacement"
                      label="Copy from replacement (origin)"
                      item-text="replacement.replacementProgramDescription"
                      item-value="replacementId"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-select
                      box
                      :disabled="loading"
                      :items="clientReplacements"
                      v-model="targetReplacement"
                      label="to replacement (target)"
                      item-text="replacement.replacementProgramDescription"
                      item-value="replacementId"
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-flex xs12 pa-0 ma-0>
                  <small class="red--text"> * This action will remove/replace all data in the target {{ collection }} </small>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <!-- For reference: OriginID: {{ originReplacement }} - TargetID:{{ targetReplacement }} -->
          <v-spacer />
          <v-btn :disabled="loading" :loading="loading" color="primary" @click="copy">Copy</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import userApi from "@/services/api/UserServices";
import clientApi from "@/services/api/ClientServices";
import sessionServicesApi from "@/services/api/SessionServices";

export default {
  data() {
    return {
      loading: false,
      required: (value) => !!value || "This field is required.",
      validForm: false,
      clients: [],
      clientId: null,
      clientBehaviors: [],
      clientReplacements: [],
      collection: "behavior",
      originBehavior: null,
      targetBehavior: null,
      originReplacement: null,
      targetReplacement: null
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    }
  },

  mounted() {
    this.$store.commit("SET_ACTIVE_CLIENT", null);
    this.loadUserClients();
  },

  methods: {
    async loadUserClients() {
      this.clients = [];
      this.loading = true;
      try {
        this.clients = await userApi.loadUserClients();
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    },

    async clientSelect(c) {
      this.loading = true;
      try {
        this.problemsArray = [];
        this.action = null;
        this.clientBehaviors = await sessionServicesApi.getClientBehaviors(c, false);
        this.clientReplacements = await sessionServicesApi.getClientReplacements(c, false);
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    },

    async copy() {
      if (this.collection === "behavior" && (!this.originBehavior || !this.targetBehavior || this.originBehavior == this.targetBehavior)) {
        this.$toast.error("Error in origin and/or target behavior, you must select both behaviors and can not be the same.");
        return;
      }
      if (this.collection === "replacement" && (!this.originReplacement || !this.targetReplacement || this.originReplacement == this.targetReplacement)) {
        this.$toast.error("Error in origin and/or target replacement, you must select both replacements and can not be the same.");
        return;
      }

      this.$confirm(
        "Are you sure you want to copy data from the selected behavior/replacement (origin) to the selected behavior/replacement (target)? <br> <strong class='red--text'>This action will delete all data in the TARGET behavior.</strong>"
      ).then(async (res) => {
        if (res) {
          try {
            this.loading = true;
            const copyModel = {
              clientId: this.clientId,
              collection: this.collection,
              originId: this.collection == "behavior" ? this.originBehavior : this.originReplacement,
              targetId: this.collection == "behavior" ? this.targetBehavior : this.targetReplacement
            };
            const count = await clientApi.copyClientDataCollect(copyModel);
            this.$toast.info(count + " data collecion copied.");
            this.originBehavior = null;
            this.targetBehavior = null;
            this.originReplacement = null;
            this.targetReplacement = null;
          } catch (error) {
            this.$toast.error(error.message || error);
          } finally {
            this.loading = false;
          }
        }
      });
    }
  }
};
</script>
